import { format } from "date-fns"
import { getAssessmentTypes } from "utils/constants"
import { getResponse, toFixedIfNumberHasDecimals } from "utils/utils"
import { CODESTAGES, INTERVIEW_TYPE, SCREENING_STAGES } from "./constants"

export const calculateTimeslotTitle = event => {
  const from = format(new Date(event.start), "HH:mm")
  let result = from
  if (event.end) {
    const to = format(new Date(event.end), "HH:mm")
    result += `-${to}`
  }
  return result
}

export const calculateDayTitle = event => {
  return format(event.start, "EEEE do MMMM yyyy")
}

export const getTimeslotFullTitle = event => {
  return calculateDayTitle(event) + " at " + calculateTimeslotTitle(event)
}

export const INSERT_TABS = [
  {
    index: 0,
    id: "structure",
    label: "Select role & stage",
  },
  {
    index: 1,
    id: "slot",
    label: "Define time slots",
  },
  {
    index: 2,
    id: "calendar",
    label: "Add availability",
  },
  {
    index: 3,
    id: "slotlist",
    label: "Review & confirm",
  },
  {
    index: 4,
    id: "invitation",
    label: "Create Invitation",
  },
]

export const EDIT_TABS = [
  {
    index: 0,
    id: "calendar",
    label: "Add availability",
  },
  {
    index: 1,
    id: "slotlist",
    label: "Review & confirm",
  },
  {
    index: 2,
    id: "invitation",
    label: "Create Invitation",
  },
]

export const hourLabel = x => {
  if (x < 60) return `${x} mins`
  if (x === 60) return `1 hour`
  if (x % 60 === 0) return `${x / 60} hours`
  return `${parseFloat(x / 60)} hours`
}

export const convertFromAPI = timeslots => {
  const resultMap = {}
  timeslots.forEach((x, index) => {
    if (!resultMap[x.parentStartDateTime]) {
      const event = {
        calendarId: "0",
        body: "" + x.parentStartDateTime + "---" + x.parentEndDateTime,
        start: new Date(x.parentStartDateTime),
        dueDateClass: "",
        end: new Date(x.parentEndDateTime),
        id: index,
        category: "time",
        slots: [],
      }
      event.title = calculateTimeslotTitle(event)
      resultMap[x.parentStartDateTime] = event
    }
    resultMap[x.parentStartDateTime].slots.push({
      start: x.startDateTime,
      end: x.endDateTime,
      interviewers: x.numberOfInterviewers,
    })
  })
  let result = []
  Object.keys(resultMap).forEach(x => {
    resultMap[x].slots.sort((a, b) => a.start - b.start)
    result.push(resultMap[x])
  })
  return result.sort((a, b) => a.start - b.start)
}

export const convertToDaySlots = (timeslots, allInterviews = []) => {
  const resultMap = {}
  timeslots.forEach((x, index) => {
    const key = format(x.parentStartDateTime, "yyyy-MM-dd")
    if (!resultMap[key]) {
      resultMap[key] = {
        start: new Date(key),
        booked: 0,
        available: 0,
        slots: [],
      }
    }
    const booked = allInterviews.filter(
      candidate => candidate.selectedTime === x.startDateTime
    )
    resultMap[key].available += x.numberOfInterviewers
    resultMap[key].booked += booked.length
    resultMap[key].slots.push({
      start: x.startDateTime,
      end: x.endDateTime,
      interviewers: x.numberOfInterviewers,
    })
  })
  let result = []
  Object.keys(resultMap).forEach(x => {
    resultMap[x].slots.sort((a, b) => a.start - b.start)
    result.push(resultMap[x])
  })
  return result.sort((a, b) => a.start - b.start)
}

const MAX_DAYS = 5

export const convertOptionsToDaySlots = timeslots => {
  const resultMap = {}
  timeslots.forEach((x, index) => {
    const key = format(x, "yyyy-MM-dd")
    if (!resultMap[key]) {
      resultMap[key] = {
        start: new Date(key),
        slots: [],
      }
    }
    resultMap[key].slots.push({
      start: x,
    })
  })
  let result = []
  Object.keys(resultMap).forEach(x => {
    resultMap[x].slots.sort((a, b) => a.start - b.start)
    result.push(resultMap[x])
  })
  result.sort((a, b) => a.start - b.start)

  return result.slice(0, MAX_DAYS)
}

export const convertToApi = schedules => {
  const result = []
  schedules.forEach(x => {
    x.slots.forEach(y => {
      result.push({
        parentStartDateTime: new Date(x.start).getTime() / 1000,
        parentEndDateTime: new Date(x.end).getTime() / 1000,
        startDateTime: y.start / 1000,
        endDateTime: y.end / 1000,
        numberOfInterviewers: y.interviewers,
      })
    })
  })
  return result
}

export const createArray = index => {
  const result = []
  for (let i = 0; i < index; i++) {
    result.push(i)
  }
  return result
}

export const getInterviewsForSlot = (allInterviews = [], timeSlot) => {
  return allInterviews.filter(x => x.selectedTime === timeSlot.start) || []
}

export const getStatusForSlot = (slot, interviewee) => {
  if (interviewee && interviewee.selectedTime) return "Booked"
  if (slot.start && slot.start < new Date().getTime()) {
    return "Passed"
  }
  return "Available"
}

export const formatIntoDays = schedules => {
  const days = []
  schedules.forEach((schedule, index) => {
    const day =
      schedule.start.getDate() +
      ":" +
      schedule.start.getMonth() +
      ":" +
      schedule.start.getFullYear()
    if (!days.find(x => x.day === day)) {
      days.push({
        past: schedule.start < new Date().getTime(),
        start: schedule.start,
        index,
        day,
        slots: [],
      })
    }
    const dayObj = days.find(x => x.day === day)
    dayObj.end = schedule.end
    schedule.slots.forEach((slot, slotIndex) => {
      dayObj.slots.push({
        ...slot,
        scheduleIndex: index,
        slotIndex,
      })
    })
  })
  return days
}

export const convertStatus = status => {
  switch (status) {
    case "SELECTED":
      return "Completed"
    default:
      return "Pending"
  }
}

export const mapCodeStage = (
  organization,
  substage,
  type,
  existingInterviews,
  interviewId
) => {
  if (type) {
    const assessment = getAssessmentTypes(organization).find(x => x.id === type)
    if (assessment) {
      return (
        assessment.stages.indexOf(substage.stage.stage.id) > -1 &&
        (!existingInterviews ||
          !existingInterviews.find(
            y => y.stage.id === substage.id && y.id !== interviewId
          ))
      )
    }
  }
  return true
}

export const isTestAssessmentType = type =>
  type === INTERVIEW_TYPE.WATSON_GLASER ||
  type === INTERVIEW_TYPE.CAPPFINITY ||
  type === INTERVIEW_TYPE.AMBERJACK ||
  type === INTERVIEW_TYPE.SHL ||
  type === INTERVIEW_TYPE.SOVA
export const isSchedulingAssessmentType = type =>
  type === INTERVIEW_TYPE.SCHEDULER || type === INTERVIEW_TYPE.SCHEDULED

export const isFeedbackAssessmentType = type =>
  type === INTERVIEW_TYPE.SCREENING || type === INTERVIEW_TYPE.SCHEDULED

export const showFeedbackColumn = codeStage =>
  codeStage === CODESTAGES.IN_PROGRESS || codeStage === CODESTAGES.COMPLETED

export const isOnHoldSubstage = substage =>
  substage.codeStage === CODESTAGES.INVITED &&
  SCREENING_STAGES.includes(substage.stage.stage.id)

export const showActionBasedOnStage = (interview, substage) => {
  if (interview && substage) {
    switch (interview.type) {
      case INTERVIEW_TYPE.SCHEDULED:
      case INTERVIEW_TYPE.SCREENING:
        return (
          substage.codeStage === CODESTAGES.PENDING ||
          substage.codeStage === CODESTAGES.IN_PROGRESS ||
          isOnHoldSubstage(substage)
        )
      default:
        return substage.codeStage === CODESTAGES.PENDING
    }
  }
}
export const isTestAlreadySent = substage =>
  substage?.codeStage === CODESTAGES.IN_PROGRESS

export const isTestInvited = substage =>
  substage?.codeStage === CODESTAGES.INVITED

export const showCancelActionBasedOnStage = (interview, substage) => {
  if (interview && substage) {
    switch (interview.type) {
      case INTERVIEW_TYPE.SCHEDULER:
        return substage.codeStage === CODESTAGES.SCHEDULED
      default:
        return false
    }
  }
}

export const showUpdateActionBasedOnStage = (interview, substage) => {
  if (interview && substage) {
    switch (interview.type) {
      case INTERVIEW_TYPE.SCREENING:
        return substage.codeStage === CODESTAGES.IN_PROGRESS
      case INTERVIEW_TYPE.SCHEDULED:
        return substage.codeStage === CODESTAGES.SCHEDULED
      default:
        return false
    }
  }
}

export const formatInterviewType = type => {
  return getAssessmentTypes(null, true).find(x => x.id === type).label
}

export const isClickableInterview = interview => {
  return (
    interview.type === INTERVIEW_TYPE.SCHEDULER ||
    interview.type === INTERVIEW_TYPE.SCHEDULED
  )
}

export const getExpiryDate = numberOfDaysToAdd => {
  if (!numberOfDaysToAdd) {
    return
  }

  let date = new Date()
  date.setDate(date.getDate() + numberOfDaysToAdd)
  const futureDateTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    0
  ) // Set time to 23:59:00
  const formattedDate = format(futureDateTime, "iiii do MMMM yyyy") // Format the date
  return formattedDate
}

export const getAssessmentsForStage = (organization, candidate) => {
  const assessments = getAssessmentTypes(organization)
  return assessments.filter(
    assessment =>
      assessment.stages.indexOf(candidate.organizationStage?.stage.id) > -1
  )
}

export const getScoreForAssessment = (
  organization,
  candidate,
  interviewType,
  scoreKey,
  label,
  isNumber = true
) => {
  const assessment = getAssessmentsForStage(organization, candidate).find(
    assessment => assessment.id === interviewType
  )
  if (assessment) {
    let value = getResponse(candidate, scoreKey)
    if (value) {
      if (isNumber) {
        value = toFixedIfNumberHasDecimals(parseFloat(value))
      }
      return {
        label,
        value,
      }
    }
  }
}
