import { getQueryParameters } from "utils/utils"

export const translateText = (translationMap, key, returnHTML = true) => {
  const { language, debug } = getQueryParameters()
  if (!language || !translationMap) return key
  if (!key) return key
  if (typeof key === "string" || key instanceof String) {
    if (!translationMap[language]) {
      if (!debug) return key
      return `Missing Language ${language}`
    } else {
      if (translationMap[language][key]) {
        return translationMap[language][key]
      } else {
        if (!debug) return key
        return returnHTML ? (
          <div style={{ color: "red" }}>Missing Translation: {key}</div>
        ) : (
          `>>>>Missing Translation: ${key}`
        )
      }
    }
  } else {
    if (!debug) return key
    return returnHTML ? (
      <div style={{ color: "red" }}>We only translate strings {returnHTML}</div>
    ) : (
      `>>>>We only translate strings`
    )
  }
}

export const getQuestionTextForLanguage = (question, key) => {
  // const { language } = getQueryParameters()
  // if (
  //   question.languageText &&
  //   question.languageText[language] &&
  //   question.languageText[language][key]
  // ) {
  //   return question.languageText[language][key]
  // }
  return question[key]
}
